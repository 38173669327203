import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/babel-webpack",
  "date": "2016-02-05",
  "title": "BABEL & WEBPACK",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Babel has been one of the big newcomers of JavaScript community in 2015. It allows us to use features from the future of JavaScript. It will transform your futuristic code into a format, browsers understand. You can even use it to develop your own language features. Babel’s built-in JSX support will come in handy here."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Babel provides support for certain experimental features from ES7 beyond standard ES6. Some of these might make it to the core language while some might be dropped altogether.`}</p>
    <h2>{`The language proposals`}</h2>
    <p>{`The core language proposals for JavaScript have been categorized within stages:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stage 0`}</strong>{` - Strawman`}</li>
      <li parentName="ul"><strong parentName="li">{`Stage 1`}</strong>{` - Proposal`}</li>
      <li parentName="ul"><strong parentName="li">{`Stage 2`}</strong>{` - Draft`}</li>
      <li parentName="ul"><strong parentName="li">{`Stage 3`}</strong>{` - Candidate`}</li>
      <li parentName="ul"><strong parentName="li">{`Stage 4`}</strong>{` - Finished`}</li>
    </ul>
    <p>{`You have to be very careful with `}<strong parentName="p">{`stage 0`}</strong>{` features - if a feature changes/removed your code will end up broken. But it's alright for smaller experimental projects.`}</p>
    <Message type="info" title="Stage 2 - Draft" content="Features starting from stage 2 have been enabled by default" mdxType="Message" />
    <h2>{`Configuring babel-loader`}</h2>
    <p>{`The easiest way of using Babel with Webpack is via `}<strong parentName="p">{`babel-loader`}</strong>{`. It takes our ES6 module definition based code and turn it into ES5 bundles.`}</p>
    <h3>{`Installing babel-loader`}</h3>
    <p>{`As usual with any node package, use NPM to install babel-loader, and babel-core, which contains core logic of Babel.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-loader babel-core --save-dev
`}</code></pre>
    <p>{`Now we need to add a loader declaration for babel-loader to the `}<strong parentName="p">{`loaders`}</strong>{` section of the config. It matches both `}<inlineCode parentName="p">{`.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`.jsx`}</inlineCode>{` using regular expression `}<inlineCode parentName="p">{`/\\.jsx?$/`}</inlineCode>{`.`}</p>
    <h2>{`Configuring Webpack`}</h2>
    <p>{`Let's restrict the loader to operate only within `}<inlineCode parentName="p">{`./app`}</inlineCode>{` directory by adding `}<strong parentName="p">{`include`}</strong>{` rule. This way, it won't traverse through directories like `}<strong parentName="p">{`node_modules`}</strong>{`. Another way of doing this is by adding an `}<strong parentName="p">{`exclude`}</strong>{` rule against `}<strong parentName="p">{`node_modules`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var webpack = require('webpack');
var path = require('path');

const config = {
  context: __dirname + '/src',
  entry: './app.js',

  resolve: {
    extensions: ['', '.js', '.jsx']
  },

  output: {
    path: __dirname + 'build',
    filename: 'bundle.js'
  },
  module: {
    loaders: [
      {
        test: /\\.css$/,
        loaders: ['style', 'css'],
        include: path.join(__dirname, 'src'),
      },
      {
        test: /\\.jsx?$/,
        loaders: ['babel?cacheDirectory'],
        include: path.join(__dirname, 'src'),
      }
    ]
  }
};

module.exports = config;
`}</code></pre>
    <p><strong parentName="p">{`Line 8`}</strong>{` - Add resolve.extensions. `}<strong parentName="p">{`''`}</strong>{` is needed to allow imports without an extension. Note the `}<strong parentName="p">{`.`}</strong>{`'s before extensions, the matching will fail without those.`}</p>
    <p><strong parentName="p">{`Line 24`}</strong>{` - This accepts `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` and `}<inlineCode parentName="p">{`js`}</inlineCode>{` thanks to Regular Expressions.`}</p>
    <p><strong parentName="p">{`Line 25`}</strong>{` - Enable caching for improved performance during development. It uses default OS directory by default. If you need something more custom, pass a path to it. i.e., `}<inlineCode parentName="p">{`babel?cacheDirectory=<path>`}</inlineCode>{`.`}</p>
    <Message type="info" title="resolve.extensions" content="As resolve.extensions gets evaluated from left to right, we can use it to control which code gets loaded for given configuration. For instance, you could have .web.js to define web specific parts and then have something like **'', '.web.js', '.js', '.jsx'**. If a **web** version of the file is found, Webpack would use that instead of the default." mdxType="Message" />
    <h2>{`Setting up .babelrc`}</h2>
    <p>{`We can pass Babel settings through Webpack, for instance `}<strong parentName="p">{`babel?presets[]=react,presets[]=es2015`}</strong>{`, but doing so it will only work for Wepback.`}</p>
    <p>{`For Babel settings to be accessed by anything, not just Webpack, we are going to create `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{`.`}</p>
    <h3>{`Plugins`}</h3>
    <p>{`Babel 6 relies on plugins. There are two types of them: syntax and transform.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Syntax`}</strong>{` - Allows Babel to parse additional syntax`}</li>
      <li parentName="ul"><strong parentName="li">{`Transform`}</strong>{` - Applies transformation`}</li>
    </ul>
    <p>{`This helps code that is using future syntax can get transformed back to JavaScript older environments can understand.`}</p>
    <h3>{`Presets`}</h3>
    <p>{`To make it easier to consume plugins, Babel supports the concept of `}<strong parentName="p">{`presets`}</strong>{`.`}</p>
    <p>{`Each preset comes with a set of plugins so, you don't have to wire them up separately. In this case, we'll be relying on ES2015 and JSX presets:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm i babel-preset-es2015 babel-preset-react --save-dev
`}</code></pre>
    <p>{`Now let's set up a `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets": [
    "es2015",
    "react"
  ]
}
`}</code></pre>
    <p>{`Babel provides stage-specific presets. This documents your project well and keeps it maintainable.`}</p>
    <Message type="info" title="resolve.extensions" content="If you don't like to maintain a `.babelrc` file, another alternative is to write the configuration below babel field at `package.json`. Babel will pick it up from there." mdxType="Message" />
    <h2>{`Alternative Loader Declarations`}</h2>
    <p>{`Webpack's loader declaration allows passing parameters to a loader through a query string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  test: /\\.jsx?$/,
  loader: 'babel',
  query: {
    cacheDirectory: true,
    presets: ['react', 'es2015']
  },
  include: path.join(__dirname, 'src')
}
`}</code></pre>
    <p>{`Note the `}<strong parentName="p">{`loader`}</strong>{` and `}<strong parentName="p">{`query`}</strong>{` fields. It can be alternatively declared as: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`loader: 'babel?cacheDirectory,pesets[]=react,presets[]=es2015'
`}</code></pre>
    <p>{`This is a bit hard to read, so the former is better for readability.`}</p>
    <p>{`It's a good idea to keep in mind that Webpack loaders are always evaluated from right to left and from bottom to top (separate definitions). The following two declarations are equivalent based on this rule:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    test: /\\.css$/,
    loaders: ['style', 'css'],
},
`}</code></pre>
    <p>{`And `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    test: /\\.css$/,
    loaders: ['style'],
},
{
    test: /\\.css$/,
    loaders: ['css'],
},
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`This tutorial will provide you with a good understanding of how Webpack and Babel play together, when it comes to building an application from the ground up and you will find it will be great for React apps.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      